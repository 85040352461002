<template>
  <Base titleIcon="el-icon-s-claim">


  <el-space wrap style="margin-bottom: 12px">
    <el-radio-group v-model="filter.source" @change="query({ pageNum: 1 })">
      <el-radio-button :label="item" v-for="(item, index) in fundWatchSourceDic" :key="item">{{ index == 0 ? "省拨" : "国拨"
        }}</el-radio-button>
    </el-radio-group>

    资金下达年份
    <el-date-picker v-model="filter.fundYearStart" type="year" placeholder="开始年份" format="YYYY" value-format="YYYY" />
    至
    <el-date-picker v-model="filter.fundYearEnd" type="year" placeholder="结束年份" format="YYYY" value-format="YYYY" />

    <el-checkbox v-model="filter.isBelongProv" label="省直属" v-show="user.inRoles(roles.prov)" />
    <el-select :disabled="filter.isBelongProv" @clear="filter.city = null" v-model="filter.city" placeholder="市级"
      clearable v-show="user.inRoles(roles.prov)">
      <el-option v-for="item in dicCity.filter((p) => p.name != '青岛市')" :key="item.id" :value="item.id"
        :label="item.name"></el-option>
    </el-select>

    <el-select :disabled="filter.isBelongProv" @clear="filter.town = null" v-model="filter.town" placeholder="区县"
      clearable v-show="user.inRoles(roles.city)">
      <el-option v-for="item in dicTown" :key="item.id" :value="item.id" :label="item.name"></el-option>
    </el-select>

    <el-select v-model="filter.status" placeholder="状态" clearable multiple>
      <el-option v-for="item in fundWatchProjectDoStatusDic" :key="item" :label="item" :value="item"></el-option>
    </el-select>

    <!-- <el-select v-model="filter.isMemo" placeholder="备注" clearable>
        <el-option label="是" :value="true"></el-option>
        <el-option label="否" :value="false"></el-option>
      </el-select> -->

    <!-- <el-input
        placeholder="主题"
        v-model="filter.primary"
        style="width: 500px"
        @keyup.enter="query"
      >
        <template #prepend> 主题 </template>
</el-input>-->
    <el-space>
      <el-input placeholder="项目名称、保护单位名称、市县" v-model="filter.keyword" style="width: 500px" @keyup.enter="query">
        <template #prepend>关键字</template>
      </el-input>

      <el-button type="primary" icon="el-icon-search" @click="query">查询</el-button>

      <el-button type="primary" icon="el-icon-download" @click="exportExcel">导出</el-button>
    </el-space>
  </el-space>
  <div v-show="user.inRoles(roles.city, roles.prov)">
    自填报项目
    <el-switch v-model="filter.selFill" @change="query({ pageNum: 1 })" />
    授权状态
    <el-select style="width: 90px" v-model="filter.hasDispatch" @change="() => {
      if (filter.hasDispatch === '') filter.hasDispatch = null;
      query({ pageNum: 1 });
    }
      " clearable>
      <el-option :value="true" label="已授权"></el-option>
      <el-option :value="false" label="未授权"></el-option>
    </el-select>

    申请验收
    <el-select style="width: 90px" v-model="filter.hasFinishCheck" @change="() => {
      if (filter.hasFinishCheck === '') filter.hasFinishCheck = null;
      query({ pageNum: 1 });
    }
      " clearable>
      <el-option :value="true" label="已申请"></el-option>
      <el-option :value="false" label="未申请"></el-option>
    </el-select>
  </div>

  <h3 style="text-align: center">文物保护项目执行情况</h3>

  <div class="fixed-scroll-content">
    <el-table :data="pagedList.list" v-loading="isLoading" class="fixed-scroll-content-table" @sort-change="({ column, prop, order }) => {
      query({ pageNum: 1, sortBy: prop, sortByDirection: order });
    }
      " :row-class-name="tableRowClassName">
      <el-table-column label="市级/省直" prop="city_Name"></el-table-column>
      <el-table-column label="区县" prop="country_Name"></el-table-column>
      <el-table-column label="文保单位" prop="heritageUnit_Name"></el-table-column>
      <el-table-column label="项目编号" prop="project_Code"></el-table-column>
      <el-table-column label="项目名称" prop="project_Name"></el-table-column>
      <el-table-column label="可移动/不可移动" prop="project_Type"></el-table-column>
      <el-table-column label="状态" prop="project_DoStatus"></el-table-column>
      <el-table-column label="技术方案名称" prop="proposalDoc_Title"></el-table-column>
      <el-table-column label="方案批复文号" prop="proposalDoc_Code"></el-table-column>
      <el-table-column label="方案批复年份" prop="proposalDoc_Year"></el-table-column>
      <el-table-column label="方案设计单位" prop="proposalDoc_DesignUnit"></el-table-column>
      <!-- <el-table-column
          label="资金到位情况"
          prop="project_Fund_Amount"
        ></el-table-column>
        <el-table-column
          label="资金使用情况"
          prop="project_Fund_Amount_Used"
        ></el-table-column> -->
      <el-table-column label="资金来源" prop="project_Fund_Source"></el-table-column>
      <el-table-column label="施工单位" prop="project_DoUnit"></el-table-column>
      <el-table-column label="监理单位" prop="project_WatchUnit"></el-table-column>
      <el-table-column label="情况描述" prop="questionDetail"></el-table-column>
      <!-- <el-table-column
        label="备注"
        prop="memo"
        v-if="user.inRoles(roles.prov)"
        ></el-table-column>-->
      <el-table-column label="填报进度" width="140">
        <template #default="{ row: item }">
          <el-button-group>
            <el-tooltip content="招标信息">
              <el-button :type="item.biddingInfos == null || item.biddingInfos.length == 0 ? 'info' : 'primary'"
                size="mini">1</el-button>
            </el-tooltip>
            <el-tooltip content="合同信息">
              <el-button :type="item.contractInfos == null || item.contractInfos.length == 0 ? 'info' : 'primary'"
                size="mini">2</el-button>
            </el-tooltip>
            <el-tooltip content="开工信息">
              <el-button
                :type="item.projectStartInfos == null || item.projectStartInfos.length == 0 ? 'info' : 'primary'"
                size="mini">3</el-button>
            </el-tooltip>
            <el-tooltip content="自查自验">
              <el-button type="info" size="mini">4</el-button>
            </el-tooltip>
            <el-tooltip content="初验信息">
              <el-button :type="item.firstCheckInfos == null || item.firstCheckInfos.length == 0 ? 'info' : 'primary'"
                size="mini">5</el-button>
            </el-tooltip>
            <el-tooltip content="四方验评">
              <el-button :type="item.unionCheckInfos == null || item.unionCheckInfos.length == 0 ? 'info' : 'primary'"
                size="mini">6</el-button>
            </el-tooltip>
          </el-button-group>
        </template>
      </el-table-column>
      <el-table-column label="更新日期" prop="update_Date" sortable="custom">
        <template #default="{ row: item }">{{
          parseTime(item.update_Date, "{yyyy}年{mm}月{dd}日")
        }}</template>
      </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template #default="{ row: item }">
          <el-button v-if="user.inRoles(roles.city, roles.prov, roles.fundCountyManager) &&
            item.finishCheckStatus != 2
          " type="text" @click="dispatchShow(item)" :loading="isProcessing"
            :style="{ color: item.submit_UserId ? 'red' : '#409EFF' }" icon="el-icon-user">

            <el-tooltip v-if="item.submit_UserId" :content="item.submit_UserName" placement="top">
              <span>已授权</span>
            </el-tooltip>
            <el-tooltip v-else content="授权">
              <span>授权</span>
            </el-tooltip>
          </el-button>

          <!-- <el-button type="text" v-if="user.inRoles(roles.prov)" @click="showMemoEdit(item)">
            <el-tooltip
              :content="item.memo"
              placement="top"
              v-if="item.memo != null && item.memo != ''"
            >
              <i class="el-icon-document-checked IconGreen"></i>
            </el-tooltip>
            <i v-else class="el-icon-document-add IconBule"></i>
            </el-button>-->

          <el-button type="text" v-if="user.inRoles(roles.apply) &&
            user.id == item.submit_UserId &&
            !item.finishCheckStatus
          " @click="showEdit(item)">
            <el-tooltip content="填报" placement="top">
              <span>
                <i class="el-icon-edit"></i>
                填报
              </span>
            </el-tooltip>
          </el-button>
          <!-- <el-button type="text" v-if="(user.inRoles(roles.apply) && user.id == item.submit_UserId) ||
      item.finishCheckStatus == 1 ||
      (item.finishCheckStatus == 2 && user.inRoles(roles.prov))
      " @click="showCheckEdit(item)">
            <el-tooltip content="验收" placement="top">
              <span>
                <i class="el-icon-check"></i>
                验收
              </span>
            </el-tooltip>
          </el-button> -->
          <el-button type="text" v-if="user.inRoles(roles.prov, roles.city)" @click="showView(item)">
            <el-tooltip content="查看" placement="top">
              <span>
                <i class="el-icon-view"></i>
                查看
              </span>
            </el-tooltip>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <Pager :pager="pager" @change="query"></Pager>
  <StatusSubmit :setting="setting" @change="query" :action="formAction"></StatusSubmit>

  <StatusFinish :setting="statusFinishSetting" @change="query" v-if="statusFinishSetting.visible"></StatusFinish>
  <DispatchUser :setting="dispatchUserSetting" @selected="onSelected" v-if="dispatchUserSetting.visible"></DispatchUser>
  </Base>
</template>

<script lang="ts">

import { parseTime } from "@/utils/index.js";
import {
  fundWatchProjectDoStatusDic,
  fundWatchSourceDic,
} from "@/network/lookUp.ts";
import roles from "@/types/roles.ts";
import { ElMessageBox, ElNotification } from "element-plus";
import { useCityRelTown } from "@/network/chinaArea";
import Pager from "@/views/layout/pager.vue";
import StatusFinish from "./statusFinish.vue";
import StatusSubmit from "./statusSubmit.vue";
import DispatchUser from "./dispatchUser.vue";
import Base from "@/views/layout/Base.vue";
import { useQueryProject, useFundWatchAction } from "@/network/fundWatch";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
  toRef,
  inject,
} from "vue";
import { openWindowWithToken, openPostWindow } from "@/utils/util";
export default defineComponent({
  components: {
    Base,
    StatusFinish,
    StatusSubmit,
    Pager,
    DispatchUser,
  },
  setup() {
    const user: any = inject("user");
    const statusFinishSetting = reactive({
      visible: false,
      id: null,
      title: null,
      data: {},
    });

    const dispatchUserSetting = reactive({ visible: false, item: null });
    const formAction = ref();
    const setting = reactive({ visible: false, id: null, data: {}, active_Name: "project" });
    const showEdit = (item) => {
      formAction.value = "edit";
      setting.visible = true;
      setting.id = item.id;
      // setting.data = item;
    };

    const showView = (item) => {
      formAction.value = "view";
      setting.id = item.id;
      console.log(setting.id);
      setting.visible = true;
      // // setting.data = item;
    };

    const [isProcessing, , , projectDispatch] = useFundWatchAction();
    const yearDic = [];

    let startYear = new Date().getFullYear() + 1;
    for (let i = 0; i <= 10; i++) {
      yearDic.push(startYear--);
    }

    const filter = reactive({
      primary: null,
      isMemo: null,
      year: null,
      source: "省级财政",
      keyword: null,
      city: null,
      town: null,
      pageNum: 1,
      pageSize: 20,
      status: null,
      fundYearStart: null,
      fundYearEnd: null,
      selFill: false,
      sortBy: null,
      sortByDirection: null,
      isBelongProv: false,
      hasDispatch: null,
      hasFinishCheck: null,
    });

    if (user.inRoles(roles.fundCountyManager)) {
      filter.town = user.county_ID;
    } else if (user.inRoles(roles.city)) {
      filter.city = user.city_ID;
    }

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );
    const [isLoading, pagedList, query, pager] = useQueryProject(filter);

    const onSelected = (id) => {
      projectDispatch(dispatchUserSetting.item.id, id).then(() => {
        query();
      });
    };
    onMounted(() => {
      query();
    });

    const exportExcel = () => {
      openPostWindow(
        `${process.env.VUE_APP_BASE_API}/FundWatch/export/queryProject`,
        filter
      );
    };

    const dispatchShow = (item) => {
      dispatchUserSetting.visible = true;
      dispatchUserSetting.item = item;
    };

    const showCheckEdit = (item) => {
      statusFinishSetting.visible = true;
      statusFinishSetting.title = item.project_Name;
      statusFinishSetting.id = item.id;
      statusFinishSetting.data = item;
    };

    const showMemoEdit = (item) => {
      ElMessageBox.prompt("请填写备注内容", "备注", {
        inputValue: item.memo,
        type: "warning",
        customClass: "backConfirmForm",
        confirmButtonText: "保存",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPattern: /(.+){5,}/,
        inputErrorMessage: "内容不得少于5个字！",
        inputPlaceholder: "请输入备注内容",
      }).then(({ value }) => {
        item.memo = value;

        //run(id, { forceFlag: 1, memo: value });
      });
    };
   

    const tableRowClassName = ({
      row,
      rowIndex,
    }) => {
      // console.log("aaaaa")
      // if (rowIndex === 1) {
      //   return 'warning-row'
      // } else if (rowIndex === 0) {
      //   return 'warning-row'
      // }
      // else if (rowIndex === 4) {
      //   return 'warning-row'
      // }
      // else if (rowIndex === 5) {
      //   return 'warning-row'
      // }
      return ''
    }

    return {
      tableRowClassName,
      formAction,
      statusFinishSetting,
      isProcessing,
      dispatchUserSetting,
      dispatchShow,
      onSelected,
      showView,

      exportExcel,
      fundWatchProjectDoStatusDic,
      fundWatchSourceDic,
      yearDic,
      dicCity,
      dicTown,
      filter,
      setting,
      showEdit,
      isLoading,
      pagedList,
      query,
      pager,
      user,
      roles,
      showMemoEdit,
      showCheckEdit,
      parseTime,
    };
  },
});
</script>

<style scoped></style>

<style>
.el-table .warning-row {
  background-color: #E6A23C;
  color: white;

}
</style>